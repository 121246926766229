import React, { useEffect, useState } from "react";
import { Container, Button } from 'react-bootstrap';
import { IoMdWallet } from 'react-icons/io';
import { MdCurrencyRupee } from 'react-icons/md';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from "react-router-dom";

export default function Transferpoints() {
  const [number, setNumber] = useState('');
  const [points, setPoints] = useState('');
  const [numberError, setNumberError] = useState('');





  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    loaduser();
  }, []); // Empty dependency array for initial component mount

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    try {
      const response = await axios.post(url, formData);
      console.log("API Response:", response);
      const res = response.data;
      if (res !== undefined) {
        setProfiles(res);
        console.warn(res);
      }
    } catch (error) {
      console.error("Error fetching profile data: ", error);
    }
  };

  const handleInputPoints = (e) => {
    setPoints(e.target.value);
  };

  const navigate = useNavigate();

  const handleInputNumber = (e) => {
    setNumber(e.target.value);
  };

  const loadUser = async () => {
    try {
      if (!number) {
        setNumberError("Please enter your Paytm number.");
        return;
      } else {
        setNumberError('');
      }

      if (number.length !== 10) {
        setNumberError("Please enter a 10-digit Paytm number.");
        return;
      } else {
        setNumberError('');
      }

      const user_id = localStorage.getItem('userid');
      const dev_id = localStorage.getItem('dev_id');
      const url = `${process.env.REACT_APP_API_URL}/point_transfer.php`;

      const newFormData = new FormData();
      newFormData.append('app_id', process.env.REACT_APP_API_ID);
      newFormData.append('user_id', user_id);
      newFormData.append('dev_id', dev_id);
      newFormData.append('mob', number);
      newFormData.append('amount', points);

      const response = await axios.post(url, newFormData);
      const responseData = response.data;

      const MySwal = withReactContent(Swal);
      if (responseData.success === '1') {
        MySwal.fire({
          title: responseData.message,
          icon: 'success',
          timer: 2500,
        }).then((result) => {
          navigate('/');
        });
      } else {
        MySwal.fire({
          title: responseData.message,
          timer: 2000,
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error submitting payment details:', error);
    }
  };

  return (
    <section id="transferpoints" className="bg-image_all">
      <Container fluid>
        <div className="bg-white transferpoints">
          <div className="d-flex justify-content-center align-items-center">
            <IoMdWallet className="wallet_color" />
            <MdCurrencyRupee className="text-dark" />
            <h3 className="mb-0">{profiles.credit}</h3>
          </div>
          <div className="input_number position-relative">
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                value={number}
                onChange={handleInputNumber}
                isInvalid={numberError}
                required
              />
              <label>Number</label>
            </div>
            <span className="text-right text-dark">1/10</span>
          </div>
          <p className="mb-0 text-danger text-center">Username not verify Please Verify!</p>
          <div className="d-flex justify-content-end">
            <Button className="verify" onClick={loadUser}>
              VERIFY
            </Button>
          </div>
          <div className="input_number">
            <div className="form-group">
              <input
                type="number"
                className="form-control"
                value={points}
                onChange={handleInputPoints}
                required
              />
              <label>Points</label>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
}
