import React, { useState, useEffect } from "react";
import "./Login.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Outlet, Link, Await } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Container from "react-bootstrap/Container";
import Logoimg from "../../assets/img/logo.png";
import { NumberOutlined, UserOutlined, LockOutlined } from "@ant-design/icons";
import { Col, Row, Form, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPWAInstallProvider, { useReactPWAInstall } from "react-pwa-install";
import Cookies from "js-cookie";
import { Spinner } from "react-bootstrap";

import { v4 as uuidv4 } from "uuid";


function Login() {
  const mobile1 = window.innerWidth <= 768 ? true : false;
  const [loading, setLoading] = useState(false);



  // const [loadingsplash, setloadingsplash] = useState(true);
  // useEffect(() => {

  //   const splashTimeout = setTimeout(() => {
  //     setloadingsplash(false);
  //   }, 1000); // 3000ms (3 seconds) delay

  //   return () => clearTimeout(splashTimeout);
  // }, []);


  const [browserId, setBrowserId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    let browserIdd = Cookies.get("browserId");
    if (!browserIdd) {
      browserIdd = uuidv4();
      Cookies.set("browserId", browserIdd, { expires: 7 }); // Store the ID in a cookie that expires in 365 days
    }
    setBrowserId(browserIdd);

    // console.log('Browser ID:', browserIdd);
  }, [mobile1]);




  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallButton, setShowInstallButton] = useState(true);

  var token = localStorage.getItem("userid");

  // useEffect(() => {
  //   if (token) {
  //     window.location.href = '/Home';
  //   }
  // }, [])

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (event) => {
      event.preventDefault();
      setDeferredPrompt(event);
    });

    return () => {
      window.removeEventListener("beforeinstallprompt", (event) => {
        event.preventDefault();
        setDeferredPrompt(event);
      });
    };
  }, []);

  useEffect(() => {
    const userid = localStorage.getItem("userid");
    if (userid) {
      navigate('/');
    }
    // Check if the app has been installed (stored in local storage)
    const isAppInstalled = localStorage.getItem("isAppInstalled");
    if (isAppInstalled) {
      setShowInstallButton(false);
    }
  }, []);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();

      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the A2HS prompt");

          localStorage.setItem("isAppInstalled", "true");
          setShowInstallButton(false);
        } else {
          console.log("User dismissed the A2HS prompt");
        }

        setDeferredPrompt(null);
      });
    }
  };

  const onFinish = (values) => {
    // console.log('Success:', values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  localStorage.setItem("token", "");
  const [user, setUserts] = useState({
    mobile: "",
  });

  const [motp, setMotp] = useState({
    mobile: "9782140792",
    otp: "123456",
  });

  const [mobile, setmobile] = useState("");
  const [password, setPassword] = useState();

  const onInputChangemobile = (e) => {
    setmobile(e.target.value);
  };

  const onInputChangepassword = (e) => {
    setPassword(e.target.value);
  };

  const Submit = async () => {
    const devid = localStorage.getItem("dev_id");
    const user_id = localStorage.getItem("userid");
    setLoading(true);

    console.log("jh");
    // localStorage.getItem("user_id", response.data.user_id);
    // alert("bdvcdxvc");
    try {
      //   const formData = new FormData();
      //   formData.append('mobileNum', mobile);
      //  formData.append('pass', password );
      //  formData.append('dev_id', devid );
      //  formData.append('app_id', process.env.REACT_APP_API_ID);

      //   const response =  axios.post(`${process.env.REACT_APP_API_URL}/POM_login.php`, formData);
      let url = `${process.env.REACT_APP_API_URL}/login.php`;

      const formData = new FormData();
      formData.append("mobileNum", mobile);
      formData.append("pss", password);
      formData.append("userid", user_id);
      formData.append("dev_id", browserId);

      formData.append("app_id", process.env.REACT_APP_API_ID);
      // formData.append('device_id', 'hello');
      const config = {
        method: "POST",
        url: url,
        data: formData,
      };

      axios(config).then(function (response) {
        // const res = JSON.stringify(response.data);
        // console.warn(response.data);
        // const objectRes = JSON.parse(res);
        // console.log(objectRes);
        // alert(response.data.dev_id);
        if (response.data.success == 1) {
          console.warn(response.data.success);
          Swal.fire({
            icon: "success",
            title: "Success!",
            text: "Login  successfully.",
            timer: 2000,
          }).then((result) => {
            navigate('/');
            localStorage.setItem("userid", response.data.user_id);
            localStorage.setItem("dev_id", response.data.device_id);
            console.warn(response.data.device_id);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Login Failed Please try again !",
            text: response.data.message,
            timer: 2000,
          });
        }
      })
      .finally(() => {
        // setTimeout(() =>{
        setLoading(false);
    // },5000);
    });

    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
    }
  };

  return (
    <>
      {/* <div className={`splash-screen ${loadingsplash ? "visible" : "hidden"}`}>
        <div class="logonew">
          <img src={Logoimg} className="img-fluid" />
        </div>
       
      </div> */}
      <div className="Loginpage">
        <div className="logobgshape d-flex justify-content-center align-items-center ">
          <div className="logo ">
            <img src={Logoimg} width="150" />
          </div>
        </div>

        <div className="loginform">
          <div className="bg-white login-form">
            <h3 className="mb-0 fw-bold text-center text-secondary">
              Welcome Back
            </h3>
            <p className="text-center mb-0 pleaselogin">
              Please Login To Your Account
            </p>

            <Row justify={"center"}>
              <Col xs={24} xl={8}>
                <div className="form-login-design">
                  <Form
                    name="basic"
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{
                      maxWidth: 600,
                    }}
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                  >
                    <Row justify={"center"}>
                      <Col span={24} xl={24} lg={24} md={24} sm={24}>
                        <Form.Item
                          name="username"
                          prefix={
                            <UserOutlined className="site-form-item-icon" />
                          }
                          rules={[
                            {
                              required: true,
                              message: "Please input your Number!",
                            },
                          ]}
                        >
                          <div className="w-100">
                            <Input
                              className="username w-100"
                              name="mobileNum"
                              placeholder="Mobile Number"
                              value={mobile}
                              onChange={onInputChangemobile}
                              prefix={
                                <UserOutlined
                                  className="site-form-item-icon"
                                  style={{ padding: "9px" }}
                                />
                              }
                            />
                            {/* {isValidMobile ? <p>Valid mobile number!</p> : <p>Invalid mobile number</p>} */}
                          </div>
                        </Form.Item>
                      </Col>

                      <Col span={24} xl={24} md={24}>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: "Please input your Password!",
                            },
                          ]}
                        >
                          <Input
                            className="username w-100"
                            prefix={
                              <LockOutlined className="site-form-item-icon" />
                            }
                            type="password"
                            style={{ padding: "9px" }}
                            placeholder="Password"
                            value={password}
                            onChange={onInputChangepassword}
                          />
                        </Form.Item>
                      </Col>
                      {/* <Col span={24} xl={24} md={24}>
                        <Form.Item
                          wrapperCol={{
                            span: 24,
                          }}
                        >

                        </Form.Item>
                      </Col> */}
                    </Row>
                    <Form.Item className="text-center mb-0">
                      <Link className="link btn text-center" to="/ForgotPassword">
                        Forgot Password ?
                      </Link>
                    </Form.Item>

                    <Form.Item>
                      <button
                        type="button"
                        disabled={loading}
                        className="d-block w-100 text-white bg-green refer-button cxy otp_login submit_data btn"
                        id="submit_data"
                        name="submit_data"
                        onClick={Submit}
                      >
                        Login
                        {loading && <Spinner animation="border" className="ml-2"  style={{width:'20px', height:'20px'}}/>}
                      </button>
                    </Form.Item>


                    <Form.Item className="text-center mb-0">
                      <Link className="link btn text-center" to="/Register">
                        Register Now ?
                      </Link>
                    </Form.Item>

                  </Form>

                  {/* <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-ronded text-light"
                      onClick={handleInstallClick}
                    >
                      Install Web Application
                    </button>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
