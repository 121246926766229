import React, { useState, useRef, useEffect } from "react";
// import { Button } from 'react-bootstrap';
import { Container, Form, Row, Col, ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import * as Icon from "react-bootstrap-icons";
import { FaArrowLeft } from "react-icons/fa";

import axios from "axios";
import { toast } from "react-toastify";
import loading from "../../assets/img/loading-gif.gif";
import filesearch from "../../assets/img/filesearch.png";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Toast } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import { Container, Form, Button } from "react-bootstrap";
import { Spinner } from "react-bootstrap";

export default function StarlineGali() {
  const [areCombinationsGenerated, setAreCombinationsGenerated] =
    useState(false);
  const [items, setItems] = useState([]);
  const [num, setNumber] = useState([]);
  const [values, setValues] = useState([]);
  const [isListVisible, setIsListVisible] = useState(true);
  const [session, setSession] = useState("");
  const [OpenSession, setOpenSession] = useState("");
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  const [st, setSt] = useState([]);
  const [points, setPoints] = useState();
  const [totalPoints, setTotalPoints] = useState();
  const [MaxbetAmt, setMaxbet] = useState([]);
  const [setminibet, setMinibet] = useState([]);
  const [Maxpoints, setMaxpoints] = useState([]);
  const [selectedResultType, setSelectedResultType] = useState("result");
  const [appmanagerdata, setAppmanager] = useState(null);
  const [loading2, setLoading2] = useState(false);
  const [currentDate, setCurrentDate] = useState(getFormattedDate());
  const market_name = localStorage.getItem("market_name");
  const [profiles, setProfiles] = useState([]);
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    let month = today.getMonth() + 1;
    let day = today.getDate();
    month = month < 10 ? `0${month}` : month;
    day = day < 10 ? `0${day}` : day;

    return `${year}-${month}-${day}`;
  }
  useEffect(() => {
    const timer = setInterval(() => {
      setUsers((prevState) => {
        const newBetpointChangeTime = prevState.betpoint_change_time > 0 ? prevState.betpoint_change_time - 1 : 0;
        return {
          ...prevState,
          betpoint_change_time: newBetpointChangeTime,
        };
      });
    }, 1000);
  
    return () => clearInterval(timer);
  }, []);
  const formatTime = (users) => {
    const hrs = Math.floor(users.betpoint_change_time / 3600);
    const mins = Math.floor((users.betpoint_change_time % 3600) / 60);
    const secs = users.betpoint_change_time % 60;
    const formattedHrs = hrs.toString().padStart(2, "0");
    const formattedMins = mins.toString().padStart(2, "0");
    const formattedSecs = secs.toString().padStart(2, "0");

    return `${formattedHrs} : ${formattedMins} : ${formattedSecs}`;
  };

  var checkedRadio = document.querySelector(
    'input[name="session_name"]:checked'
  );

  var game_session = checkedRadio
    ? checkedRadio.value
    : "default_session_value";

  const handleButtonClick = () => {
    if (!validateEnteredNumber() || !validatePoints()) {
      return;
    }

    const newItem = {
      // session: "open",
      digit: num,
      points: points,
    };

    setItems((prevItems) => [...prevItems, newItem]); // Add new item to the list

    // Update total points
    setTotalPoints((prevTotal) => prevTotal + parseInt(points, 10));

    // Clear input fields
    setNumber("");
    setPoints("");
  };
  const validateEnteredNumber = () => {
    const isValid = /^\d+$/.test(num);
    if (!isValid) {
      toast.error("Please enter a valid number");
    }
    return isValid;
  };

  const validatePoints = () => {
    const isValid = /^[1-9]\d*$/.test(points) && parseInt(points, 10) >= 10;
    if (!isValid) {
      toast.error("Minimum bet ₹ 10");
    }
    return isValid;
  };

  const handleDeleteItem = (index) => {
    const updatedItems = [...items];
    const deletedItem = updatedItems.splice(index, 1)[0];

    setTotalPoints((prevTotal) => prevTotal - parseInt(deletedItem.points, 10));

    setItems(updatedItems);
  };
  useEffect(() => {
    loaduser();
    chktime();
    app_manager();
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const app_manager = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/app_manager.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    // formData.append('market_id', gameid);
    formData.append("dev_id", dev_id);
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      var setpoints = response.data.points;
      setMaxpoints(setpoints);

      // console.warn(min_redeem)
      // setDeposit(mindeposit);
      const res = JSON.stringify(response.data.data);
      const objectRes = JSON.parse(res);
      setAppmanager(objectRes);
      var setmininumbet = objectRes.jodi_min;
      console.warn(setmininumbet);
      setMinibet(setmininumbet);
      var setmax_betnumbet = objectRes.jodi_max;
      console.warn(setmax_betnumbet);
      setMaxbet(setmax_betnumbet);
      // console.warn(setmax_betnumbet);
    });
  };
  const loaduser = async () => {
    // setLoading(true);
    const user_id = localStorage.getItem("userid");
    const gameid = localStorage.getItem("market_id");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id);
    try {
      let url = `${process.env.REACT_APP_API_URL}/num_tbl.php`;
      // console.warn(url);
      const formData = new FormData();
      formData.append("app_id", process.env.REACT_APP_API_ID);
      formData.append("user_id", user_id);
      formData.append("market_id", gameid);
      formData.append("dev_id", dev_id);
      var config = {
        method: "POST",
        url: url,
        body: formData,
      };
      axios
        .post(url, formData, config)
        .then(function (response) {
          var setmininumbet = response.data.mini_bet;
          // var setmax_betnumbet = response.data.max_bet;
          var setpoints = response.data.points;
          setMinibet(setmininumbet);
          // setMaxbet(setmax_betnumbet)
          setMaxpoints(setpoints);
          console.warn(setpoints);
          const res = JSON.stringify(response.data);
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes.points);
        })
        .finally(() => {
          // setLoading(false);
        });
    } catch (error) {
      console.error("There was a problem with the fetch operation:", error);
      //   setLoading(false);
    }
  };
  const chktime = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    const gameid = localStorage.getItem("market_id");

    let url = `${process.env.REACT_APP_API_URL}/get_session.php`;
    const formData = new FormData();
    formData.append("market_id", gameid);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.warn("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setOpenSession(res["open_session"]);
          console.warn(res["open_session"]);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  const playgamecrossing = async () => {
    const gameid = localStorage.getItem("market_id");
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    // const validatePoints = () => {
    //   const isValid = /^[1-9]\d*$/.test(points) && parseInt(points, 10) >= 10;
    //   return isValid;
    // };
    // if (!validatePoints()) {
    //   toast.error("Minimum bet ₹ 10");
    //   return;
    // }
    if (parseInt(setminibet) > parseInt(points)) {
      toast.error(`Minimum Bet Placed ${appmanagerdata.jodi_min}`);
      return;
    }
    if (parseInt(MaxbetAmt) < parseInt(points)) {
      console.warn(MaxbetAmt);
      toast.error(`Maximum Bet Placed ${appmanagerdata.jodi_max}`);
      return;
    }
    if (users.points < points) {
      toast.error(`You Dont Have Sufficient Balance`);
      return;
    }

    try {
      setLoadingbutton(true);

      const betList = items
        .map((item, index) => {
          return {
            betkey: item.digit, // Access the 'digit' property
            betamount: item.points, // Access the 'points' property
            session_name: 'starline',
            bettype: "1",
          };
        })
        .filter((item) => item.betamount > 0);

      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/play_game.php`,
        {
          user_id: user_id,
          dev_id: dev_id,
          market_id: gameid,
          app_id: process.env.REACT_APP_API_ID,
          BetList: betList,
          dev_model: "web",
          market_type: "starline",
          devName: "sumsang",
        }
      );

      setIsButtonVisible(true);
      if (response.data.success == 1) {
        // setShowSubmitButton(false);

        // console.warn(response.data.success)
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          icon: "success",
          timer: 2500,
        }).then((result) => {
          navigate("/");
        });
      } else {
        const MySwal = withReactContent(Swal);
        MySwal.fire({
          title: response.data.message,
          timer: 2000,
          icon: "error",
        });
        return;
      }
      // setCurrentDate(getFormattedDate());

      // return;
    } catch (error) {
      console.error("Game store:", error);
      toast.error(
        "An error occurred while Game store. Please try again later."
      );
    } finally {
      setLoadingbutton(false);
    }
  };
  const totalpoint = items.reduce(
    (acc, item) => acc + parseInt(item.points, 10),
    0
  );

  useEffect(() => {
    Getprofile();
  }, []);

  const Getprofile = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    var config = {
      method: "POST",
      url: url,
      data: formData, // Use 'data' instead of 'body'
    };

    axios(config)
      .then(function (response) {
        console.log("API Response:", response);
        const res = response.data;
        if (res !== undefined) {
          // alert(res);
          setProfiles(res);
          console.warn(res);
        }
      })
      .catch(function (error) {
        console.error("Error fetching profile data: ", error);
      });
  };
  const handelclick = () => {
    // alert('hhh')
    navigate(-1);
  }
  return (
    <>
      <div className="header-top">
        <Container>
          <Row className="d-flex align-items-center justify-content-between">
            <Col xs={2} md={2} s={2}>
              <Link onClick={handelclick} class="arrowlink">
                <FaArrowLeft />
              </Link>
            </Col>
            <Col xs={8} md={8} s={8}>
              <div className="menuicon d-flex justify-content-center">
                <div className="d-flex">
                  <div class="headericonarrow">
                  </div>
                  {market_name}
                </div>

              </div>
            </Col>

            <Col xs={2} md={2} s={2}>
              <div className="d-flex justify-content-end align-items-center">
                <div className="d-flex align-items-center wallet">
                  <Icon.Wallet /> :
                  {profiles.credit}
                </div>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <div className="container"> */}
      <section id="crossing">
        <div className="Add_bank-detail WithdrawAmt gali">
          <div className="inputbox">
            <h3 className="bg-white count form-control">{formatTime(users)}</h3>
          </div>
          <div className="d-flex justify-content-between px-3">
            <div className="points"></div>
          </div>
          <div className="inputbox">
            {/* <div className="inputbox mt-3">
              <ul className="Session_tabs bg-light d-flex rounded">
                <li className="d-flex align-items-center">
                  <div className="tabs">
                    {OpenSession == "open" ? (
                      <input
                        type="radio"
                        name="session_name"
                        value="open"
                        onChange={() => setSession("open")}
                        checked
                      />
                    ) : (
                      <input
                        type="radio"
                        name="session_name"
                        value="open"
                        disabled
                      />
                    )}

                    <label className="text-dark">Open</label>
                  </div>
                </li>
                <li className="d-flex align-items-center">
                  <div className="tabs ">
                    {OpenSession == "open" ? (
                      <input
                        type="radio"
                        name="session_name"
                        value="close"
                        onChange={() => setSession("close")}
                      />
                    ) : (
                      <input
                        type="radio"
                        name="session_name"
                        value="close"
                        onChange={() => setSession("close")}
                        checked
                      />
                    )}
                    <label className="text-dark">Close</label>
                  </div>
                </li>
              </ul>
            </div> */}
            <div className="justify-conten-between formnumber">
              <div className="form-group d-flex align-items-center">
                <label className="text-white">Digit</label>
                <Form.Control
                  type="number"
                  pattern="[0-9]"
                  maxLength="1"
                  placeholder="Enter Digit 0 to 9"
                  value={num}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    setNumber(inputValue);
                  }}
                />
              </div>
            </div>
            <div className="formnumber">
              <div className="form-group d-flex align-items-center">
                <label className="text-white">Points</label>
                <input
                  type="number"
                  inputMode="numeric"
                  className="form-control"
                  placeholder="Enter Point Here"
                  value={points}
                  onPaste={(e) => {
                    e.preventDefault();
                    const pastedText = e.clipboardData
                      .getData("text/plain")
                      .replace(/[^0-9]/g, "")
                      .slice(0, 2);
                    document.execCommand("insertText", false, pastedText);
                  }}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/[^0-9]/g, "");
                    setPoints(inputValue);
                  }}
                  required
                />
              </div>
            </div>

            <div className="form-btn">
            {users.betpoint_change_time > 0 ? (
                <button className="btn-add w-100" onClick={handleButtonClick}>
                  Add
                </button>
              ) : (
                <button className="btn-addclose w-100">
                  Bet Has Been Closed
                </button>
              )}
            </div>
          </div>

          <div className="totalpoints d-flex justify-content-between align-items-center">
            <div className="d-flex justify-content-between align-items-center">
              <p className="text-black"> ₹</p>
              <p className="ms-1 text-black">{totalpoint} /-</p>
            </div>

            {users.betpoint_change_time > 0 ? (
              <>
                <button
                  className="btn-add"
                  onClick={playgamecrossing}
                  disabled={loadingbutton || items.length === 0}
                >
                  <span>Submit</span>
                  {loadingbutton && <Spinner animation="border" />}
                </button>
              </>
            ) : (
              <button className="btn-add">
                <span>Bat Has Bin Colsed</span>
              </button>
            )}
          </div>
          <div className="table-responsive">
            <table className="table tablecrossing mb-2 mt-2">
              {items.length > 0 ? (
                <thead>
                  <tr>
                    <th>Session</th>
                    <th>Digit</th>
                    <th>Points</th>
                    <th>Action</th>
                  </tr>
                </thead>
              ) : null}
              <tbody>
                {items.map((item, index) => (
                  <tr key={index}>
                    <td>{'starline'}</td>
                    <td>{item.digit}</td>
                    <td>{item.points}</td>
                    <td
                      className="text-danger"
                      onClick={() => handleDeleteItem(index)}
                    >
                      <i className="bi bi-trash3"></i>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <ToastContainer />

      </section>
      {loading2 && (
        <div className="spinner-wrapper">
          <div className="loadernew2"></div>
        </div>
      )}
      {/* </div> */}
    </>
  );
}
